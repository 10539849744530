<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs" />
    <base-card :heading="page.title" :dialog="true" :manage="true">
      <v-row>
        <v-col cols="12" class="d-flex" style="flex-direction: column">
          <CardUser :is-editing="true" :user="user" :key="cardUserKey" />
        </v-col>
        <v-col cols="12">
          <v-tabs
            align-with-title
            v-model="tab"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
            >
              Sobre
            </v-tab>
            <v-tab
              v-if="user.data.is_certified">
              Certificados
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
            >
              <FormMyProfile @updatedUser="getUser()" :organization-name="organizationName" :role="role" :user="user" />
            </v-tab-item>
            <v-tab-item v-if="user.data.is_certified">
              <CardCertificates :name="$store.state.user.me.data.name" :finished-at="user.data.finished_training_at" :organization-name="organizationName" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import BaseBreadcrumb from '../../components/common/BaseBreadcrumb'
import BaseCard from '../../components/common/BaseCard'
import CardUser from '../../components/cards/CardUser'
import FormMyProfile from '../../components/forms/FormMyProfile'
import CardCertificates from '../../components/cards/CardCertificates'

export default {
  name: 'MyProfile',
  components: {
    CardCertificates,
    BaseBreadcrumb,
    BaseCard,
    CardUser,
    FormMyProfile
  },
  data: () => ({
    page: {
      title: 'Meu perfil'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Meu perfil',
        disabled: false,
        to: '/user/my-profile'
      }
    ],
    user: {},
    role: '',
    organizationName: '',
    tab: null,
    cardUserKey: 0
  }),
  mounted () {
    this.getUser()
  },
  methods: {
    getUser () {
      this.$axios.post('/auth/me').then(user => {
        this.user = user.data
        this.$axios.get(`/organizations/${localStorage.getItem('ACTIVE_ORGANIZATION')}`)
          .then(organization => {
            this.organizationName = organization.data.data.company.name.legal
          })
      })
    }
  }
}
</script>

<style scoped>

</style>
